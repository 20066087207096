<template>
    <div>       
        <menu-wrapper active-index="">
            <div class="secondTitle">流水记录</div>
            <div style="margin-top: 40px;"> <span>账户云淘豆：</span> <span class="cardRate">{{ UserBlance }}</span></div>
        <div  style="margin-top: 40px;">        
        <el-table :data="BalancehistoryRecord" stripe style="width: 100%;" :empty-text="emptyText" header-row-class-name="recordHeard" :cell-style="changeCellStyle"      
        @sort-change="sortchange"  :default-sort = "{prop: 'CreateTime', order: 'descending'}" 
        >
          <el-table-column prop="CreateTime" label="时间" sortable='custom'></el-table-column>
          <el-table-column prop="Balance" label="云淘豆数" :formatter="format_row_Amount" ></el-table-column>
          <el-table-column prop="Status" label="操作" sortable='custom'> </el-table-column>              
          
        </el-table>
        <!-- 分页条 -->
        <el-pagination
          style="margin-top: 10px"
          v-if="BalancehistoryRecord.length > 0"
          :pager-count="5"
          :page-sizes="Pagination.pageSizes"
          :page-size="Pagination.pageSize"
          :current-page="Pagination.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Pagination.totalSize"
          @current-change="pageChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
        </menu-wrapper>    
    </div>
</template>
<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
export default {
    components: {
      menuWrapper, 
    },
    data(){
        return {
            UserBlance: "--",
            BalancehistoryRecord: [],
            Pagination: {
                pageNumber: 1,
                totalSize: 0,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100],
            },
            emptyText:"",
            orderBy:"CreateTime descending"
        }
    },
    created() {
        this.loading = false;
        this.getInfo();
        this.getBalancehistoryRecord();      
    },
    methods: {
        getInfo() {
        this.$axios
          .get("/api/User/GetUserInfo")
          .then((res) => {
            this.UserBlance = this.$util.numberFormat(res.data.Balance);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },
      getBalancehistoryRecord() {
        this.emptyText="正在加载...";
        this.$axios
          .get(
            "/api/User/GetUserHistoryList?PageSize=" +
              this.Pagination.pageSize +
              "&PageIndex=" +
              this.Pagination.pageNumber+
              "&OrderBy="+this.orderBy
          )
          .then((res) => {
            this.BalancehistoryRecord = res.data.data;
            this.Pagination.totalSize = res.data.rowTotal;   
            if(this.BalancehistoryRecord)
            {
                this.emptyText="";      
            }
            else{
                this.emptyText="暂无数据";      
            }
             
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },
      
      handleSizeChange(val) {
        this.Pagination.pageSize = val;
        this.Pagination.pageNumber = 1;
        this.getBalancehistoryRecord();
      },
      pageChange(e) {
        this.Pagination.pageNumber = e;
        this.getBalancehistoryRecord();
      },
      changeCellStyle({ row, column, rowIndex, columnIndex }){
        if (row.Balance < 0 && columnIndex == 1) {//设置单个
            return 'color: #ff6700'
         }
      },
      format_row_Amount(row, column, cellValue, index){
            return this.$util.numberFormat(row.Balance);
      },
      // filterTimeHandler(value, row, column){
      //   const property = column['CreateTime'];
      //   return row[property] === value;
      // },
      sortchange({ column, prop, order }){  
        if(order==null){
          order="descending";
        }     
        this.orderBy=prop+" "+order;
        this.getBalancehistoryRecord();
      }
    }


}


</script>
<style scoped>
  .cardRate {
    color: #ff6700;
    font-size: 18px;
    font-weight: 600;
  }

  .title {
    font-size: 30px;
    color: #757575;
    line-height: 68px;
  }

  /deep/ .recordHeard th{
    background-color: #f0eeee;
    color: #545050;
    font-size: 15px;
  }
</style>